export function useContent() {
  const exportBranches = () => {
    return [
      {
        name: 'Cirencester',
        longName: 'Cirencester Office',
        slug: 'cirencester',
        tel: '01285 883740',
        lat: 51.716095521623586,
        lng: -1.97065297276566,
      },
      {
        name: 'Burford',
        longName: 'Burford Office',
        slug: 'burford',
        tel: '01993 822325',
        lat: 51.809946390088875,
        lng: -1.6362649622780334,
      },
      {
        name: 'Stow-on-the-Wold',
        longName: 'Stow-on-the-Wold Office',
        slug: 'stow-on-the-wold',
        tel: '01451 830731',
        lat: 51.928637207539595,
        lng: -1.719071360700331,
      },
      {
        name: 'Lettings',
        longName: 'Lettings Office',
        slug: 'lettings',
        tel: '01993 822325',
      },
      {
        name: 'London',
        longName: 'London Office',
        slug: 'london',
        tel: '0207 839 0888',
      },
    ]
  }

  const exportServices = () => {
    return [
      {
        name: 'Residential Sales',
        slug: '/property-services/sell',
        description: 'Tap into our deep understanding of the local market dynamics to maximise the value of your property.',
      },
      {
        name: 'Residential Lettings',
        slug: '/property-services/landlords',
        description: 'Bespoke and personal landlord services from a highly experienced and knowledgeable team.',
      },
      {
        name: 'Equestrian Property',
        slug: '/rural/equestrian-property',
        description: 'Extensive connections in all equestrian disciplines gives Butler Sherborn genuine insight into the requirements of equestrian buyers and property owners.',
      },
      {
        name: 'Land Sales & Acquisitions',
        slug: '/rural/land-sales-acquisitions',
        description: 'Butler Sherborn specialise in buying and selling land and farms in the Cotswolds.',
      },
      {
        name: 'Professional Valuations',
        slug: '/rural/professional-valuations',
        description: 'Our team of RICS Registered valuers have significant experience in valuing Farms, Estates, Residential and Equestrian property.',
      },

      {
        name: 'Farms & Estates',
        slug: '/rural/estates-rural-business',
        description: 'Whether dealing with a few fields, a residential portfolio, or a working farm, we are passionate about helping you achieve the most from your property.',
      },
    ]
  }

  return {
    exportBranches,
    exportServices,
  }
}
